<template>
  <div v-if="!isLoading" class="page-wrapper">
    <div class="page-header">
      <div class="container">
        <div class="page-header-inner">
          Order ID #{{ order.id }}
          <div v-if="order.status === 8" class="page-header-canceled">This order has been cancelled by customer</div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="order__wrapper">
        <div class="order__part">
          <div class="order__customer">
            <div class="order__customer-icon">
              <div class="icon-button icon-button--round icon-button--blue">
                <i class="ri-arrow-up-circle-line"></i>
              </div>
            </div>
            <div class="order__customer-name">{{ order.pickUpName }}</div>
            <div class="order__customer-address">Address: {{ order.pickUpAddress }}</div>
          </div>
          <div class="order__customer">
            <div class="order__customer-icon">
              <div class="icon-button icon-button--round icon-button--orange">
                <i class="ri-arrow-down-circle-line"></i>
              </div>
            </div>
            <div class="order__customer-name">{{ order.dropOffName }}</div>
            <div class="order__customer-address">Address: {{ order.dropOffAddress }}</div>
          </div>
        </div>
        <div class="order__part">
          <div class="order__row">
            <div class="order__row-title">Date of delivery</div>
            <div class="order__row-subtitle">{{ moment.utc(order.routeDate).format('DD MMM YYYY') }}</div>
          </div>
          <div class="order__part order__part--third">
            <div class="order__row">
              <div class="order__row-title">Service</div>
              <div class="order__row-subtitle">{{ order.serviceName }} – {{ order.servicePrice }} $</div>
            </div>
            <div class="order__row">
              <div class="order__row-title">Fuel Surcharge</div>
              <div class="order__row-subtitle">{{ order.fuelSurcharge }} $</div>
            </div>
            <div class="order__row">
              <div class="order__row-title">HST</div>
              <div class="order__row-subtitle">{{ order.hst }} $</div>
            </div>
            <div class="order__row">
              <div class="order__row-title">Total</div>
              <div class="order__row-subtitle">{{ order.total }} $</div>
            </div>
          </div>
          <div v-if="order.notes" class="order__row">
            <div class="order__row-title">Notes</div>
            <div class="order__row-subtitle">{{ order.notes }}</div>
          </div>
          <div v-if="order.attachmentPath" class="order__row">
            <div class="order__row-title">Attachment</div>
            <el-image :src="order.attachmentPath" :preview-src-list="[order.attachmentPath]" fit="cover">
              <div slot="error" class="order__image-error" />
            </el-image>
          </div>
        </div>
        <div v-if="(order.status === 6 && mePay) || (order.status === 5 && mePay)" class="order__part order__buttons">
          <button class="button button--green order__button" @click="pay">Pay</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions, mapMutations} from 'vuex'
import moment from 'moment'
import PaymentModal from '../PaymentModal'
export default {
  name: 'OrderClientPage',
  data() {
    return {
      moment,
      mePay: false,
      fileUrl: null,
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      customerID: 'payment/customers/idCustomer'
    }),
    ...mapState({
      order: s => s.orders.order
    }),
    pathID() {
      return this.$route.params.id
    }
  },
  watch: {
    async pathID(id) {
      this.isLoading = true
      await this.fetchOrder(id)
      this.isLoading = false
    }
  },
  async created() {
    const orderID = this.$route.params.id
    await Promise.all([this.fetchCustomer(), this.fetchOrder(orderID)])
    this.isLoading = false
    if (this.customerID === this.order.billingID) this.mePay = true
  },
  methods: {
    ...mapActions({
      fetch: 'orders/fetchOrder',
      fetchOrders: 'orders/fetch',
      fetchCustomer: 'payment/customers/fetchCustomer'
    }),
    ...mapMutations({
      update: 'orders/updateOrder',
      reset: 'orders/reset'
    }),
    async fetchOrder(id) {
      await this.fetch(id)
    },
    async cancel() {},
    pay() {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      this.$modal.display(
        PaymentModal,
        {
          order: this.order
        },
        {
          name: 'PaymentModal',
          transition: 'none',
          ...modalHeight,
          classes: 'modal modal-order-status'
        }
      )
    },
    updateOrder(order) {
      this.update(order)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
@import 'src/assets/styles/_variables.scss';

.order__image-error {
  background-color: $grey_border_light;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
