<template>
  <div class="payment-modal-wrapper">
    <Payment />
    <div class="payment-modal__buttons">
      <button class="button button--red" @click="cancelOrder">Cancel</button>
      <button class="button button--green" @click="payOrder">Pay</button>
    </div>
  </div>
</template>

<script>
import Payment from '../NewOrder/Payment'
import StatusModal from '../NewOrder/Status'
import {mapState} from 'vuex'

export default {
  name: 'PaymentModal',
  components: {Payment},
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      card: s => s.card.activeCard
    })
  },
  methods: {
    async payOrder() {
      const pmInfo = await this.$store.dispatch('payment/pay', {id: this.order.id, pmID: this.card.paymentID})
      const result = pmInfo.data.result
      this.openStatusScreen(result)
      this.reset()
    },
    cancelOrder() {
      this.$modal.hide('PaymentModal')
    },
    openStatusScreen(result) {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      this.$modal.display(
        StatusModal,
        {
          result,
          onlyPay: true
        },
        {
          name: 'StatusModal',
          transition: 'none',
          ...modalHeight,
          classes: 'modal modal-order-status'
        }
      )
    },
    reset() {
      this.$modal.hide('PaymentModal')
      this.$store.commit('card/reset')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
